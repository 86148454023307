import React, { useState, useEffect } from 'react';
import {
    SimpleGrid, Box, useToast, Flex, Text, SliderThumb, Center, IconButton, Button, Icon,  
    Spacer, Tooltip, InputGroup, Input, HStack,
} from '@chakra-ui/react'
import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';

import '../../css/App.css';

import MapScreen from '../../GlobalComponents/Map/MapScreen';
import WebsiteHeader from '../../GlobalComponents/WebsiteHeader';
import { FiSettings, FiRotateCw, FiAlertCircle  } from 'react-icons/fi'

import EditAbladeSt from './AbladeEdit';
import MoveAblade from './AbladeVerschieb';
import MultiSortierListe from './AbladeSortierListe';
import BaseModal from '../../GlobalComponents/BaseModal';


export default function SortManifest(props) {
    //const [TourListe, setTourListe] = useState([]);
    const [ActiveEditTour, setActiveEditTour] = useState(props.TourData);
    const [UnsetEditTour, setUnsetEditTour] = useState(props.TourUnset);

    const [Modal2, setModal2] = useState(false);
    const [ModalPos, setModalPos] = useState(0);

    const [A_EditIndex, setA_EditIndex] = useState(null);
    const [AbladeSt, setAbladeSt] = useState({});
    const [Drivers, setDrivers] = useState([]);

    const [ShowCustomer, setShowCustomer] = useState(false);

    const [index, setIndex] = useState(0);

    const toast = useToast();
    const toastIdRef = React.useRef();

    const navigate = useNavigate(); //navigate to a certain path
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params

    useEffect(() => {
        props._sortierCall('reload')
        //readQueryParams();

    },[])

    useEffect(() => {
        if(props.ActiveUser.User_Prefers !== null && props.ActiveUser.User_Prefers !== undefined && props.ActiveUser.User_Prefers.ShowLieferFirst) {
        setShowCustomer(true);
    }},[props.ActiveUser])

    useEffect(() => {
        setActiveEditTour(props.TourData);
        setUnsetEditTour(props.TourUnset);
    },[props.TourData, props.TourUnset])

    function setEditAblade(index) {

        var AbladeID = ActiveEditTour[index].AbladeID;

        if(AbladeID !== undefined ){
            searchParams.set('ablade', AbladeID)
            navigate(props.route + `/ablade?${searchParams.toString()}`);
        }
        //function continues at EditAbladeCall('setIndex');
    }
    function CustomerVisibility() {

        var Show = ShowCustomer
        setShowCustomer(!Show)
        props._sortierCall('setFirst', !Show)
    }
    async function MapCall(comand, value1, value2) {
        if (comand === 'setMap') {
            props._sortierCall('setMap', value1)
        }
    }
    function sortierCall(comand, value1, value2) {
        if (comand === 'save') {
            navigate(props.route + `/?${searchParams.toString()}`);
            props._sortierCall('save', ActiveEditTour, UnsetEditTour);
        }
        if (comand === 'back') {
            navigate(props.route + `/?${searchParams.toString()}`);
        }
        if (comand === 'sort') { //test new sorting logic
            
            var ActiveArray = [...ActiveEditTour]
            var UnsetArray = [...UnsetEditTour]

            if (value1.container === 'container1'){
                
                var item = ActiveArray.splice(value1.index, 1)
                
                if(value2.container === 'container1') {
    
                    ActiveArray.splice(value2.index, 0, item[0])
                } else {
                    item[0].Updated = 'D'
                    UnsetArray.splice(value2.index, 0, item[0])
            }} else {
                var item = UnsetArray.splice(value1.index, 1)
                console.log(item)
                if(value2.container === 'container1') {

                     item[0].Updated = 'U'
                    ActiveArray.splice(value2.index, 0, item[0])
                } else {
                    UnsetArray.splice(value2.index, 0, item[0])
            }}
            setActiveEditTour(ActiveArray);
            setUnsetEditTour(UnsetArray);
        }
    }
    async function EditAbladeCall(comand, value1, value2, value3) {
        if (comand === 'setIndex') {

            // Ablade.length === 12
            var Ablade_Idx = props.TourData.findIndex((item) => item.AbladeID == value1);
            
			if(Ablade_Idx !== -1){
                setA_EditIndex(Ablade_Idx)
            } else {
                searchParams.delete('ablade');
                navigate(props.route + `/?${searchParams.toString()}`);
            }
        }
        if (comand === 'close') {
            searchParams.delete('ablade');
            navigate(props.route + `/?${searchParams.toString()}`);
            setA_EditIndex(null);
        }
        if (comand === 'save') {
            props._sortierCall('setCoords', A_EditIndex, value1 )
        }
        if (comand === 'saveHW') {
            return props._sortierCall('saveHW', value1, value2, value3);
        }
        if (comand === 'saveLS') {
            return props._sortierCall('saveLS', value1, value2);
        }
        if (comand === 'explodeAblade' ) { //elevate all LieferSt to AbladeSt
            var result = await props._sortierCall('explodeAblade',value1, value2);
            if(result === 'success') { EditAbladeCall('close') }
            return result
        }
        if (comand === 'setMap') { props._sortierCall('setMap', value1) }
        //saveLHW
        if(comand === 'saveLHW'){ return await props._sortierCall('saveLHW', value1, value2, value3)}
        //setLCoords
        if(comand === 'setLCoords') { return await props._sortierCall('setLCoords', value1, A_EditIndex, value2 ) }
        //setLMap
        
    }
    async function MoveCall(comand, value1, value2) {

        if (comand === 'close') {
            navigate(props.route + `/?${searchParams.toString()}`);
        }
        if (comand === 'getAvailableTours') {
            return await props._sortierCall('getAvailableTours', value1, value2);
        }
        if (comand === 'getTargetAblade') {
            return await props._sortierCall('getTargetAblade', value1);
        }
        if (comand === 'getDrivers') {
            return await props._sortierCall('getDrivers');
        }
        if (comand === 'MoveAbladestellen') {
            return await props._sortierCall('MoveAbladestellen', value1);
        }
        if (comand === 'DisplayMovementResult') {
            return await props._sortierCall('DisplayMovementResult', value1, value2);
        }
    }
    async function openDriverSelect() {
        var Drivers = await props._sortierCall('getDrivers');
        ////console.log(Drivers);
        if (Array.isArray(Drivers)) {
            setDrivers(Drivers);
            setModal2(true);
        }
    }
    async function selectDriver(User_ID) {
        var result = await props._sortierCall('setNewDriver', User_ID, props.curentManifest.Manifestnummer);
        if (result === 'success') {
            setModal2(false);
        }
    }
    function makeBeladePDF(Manifest) { props._sortierCall('createBeladePDF', Manifest); }
    function makePaketPDF(Manifest) { props._sortierCall('createPaketPDF', Manifest); }
    const colorMaker = (Status) => {
        if (Status === 'done') {
            return '#d8fef0'
        } else {
            return '#fff'
        }
    }

    const ManifestEditRender = () => {
        return (
            <Flex flexDir='column'>
                <WebsiteHeader Text='TourPortal / Manifest Sortieren' onClick={() => props.viewCall('back')} server={props.server} User={props.ActiveUser} />

                <Flex height='93vh' backgroundColor='#ccc' p={1}>
                    <Flex flex={1} flexDirection='column' overflow='auto'>


                        <Box flex={1} borderWidth={1} borderRadius={3} borderColor='#000' mb={1} bg='#fff' padding={2} overflow='auto'>
                            <Flex >
                                <Text fontSize={25} fontWeight={'bold'}>Manifest: {props.TourData[0]?.Manifest}</Text>
                                <Spacer />
                                <Tooltip label="Weise der Tour einen Fahrer zu" placement='top' hasArrow bg='#666'>
                                    <Button colorScheme='twitter' onClick={() => openDriverSelect()} isDisabled={!props.UserRights.tour_manage_all} >Fahrer Zuweisen</Button>
                                </Tooltip>
                                <Tooltip label="Diese Tour begutachten" placement='top' hasArrow bg='#666'>
                                    <Button colorScheme='twitter' onClick={() => props.viewCall('switch_v')} isDisabled={false} ml='10px' borderRadius="7px 0px 0px 7px">Ansicht</Button>
                                </Tooltip>
                                <Tooltip label="Seite neu laden" placement='top' hasArrow bg='#666'>
                                    <IconButton bg='#1DA1F2' color='#fff' width='55px' height='40px' ml='2px' borderRadius="0px 7px 7px 0px"
                                    onClick={() => props._sortierCall('reload')}
                                    icon={<Icon as={ FiRotateCw } color='#fff' boxSize={35}/>}/>
                                </Tooltip>
                            </Flex>
                            <Flex m={1}>
                                <Flex flexDirection='column' marginRight={10}>
                                    <Text style={DataGrid}>Fahrer:</Text>
                                    <Text style={DataGrid}>Tour:</Text>
                                    <Text style={DataGrid}>Abladestellen:</Text>
                                    <Text style={DataGrid}>Lieferstellen:</Text>
                                    <Text style={DataGrid}>Pakete:</Text>
                                    <Text style={DataGrid}>Papier:</Text>
                                    <Text style={DataGrid}>Datum:</Text>
                                    <Text style={DataGrid}>Status:</Text>
                                </Flex>
                                <Flex flexDirection='column' marginRight={10}>
                                    <Text style={DataGrid}>{props.curentManifest.Fahrer_Name}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Tour}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Ablade_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Liefer_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Pakete_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Papier_Anzahl}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Datum}</Text>
                                    <Text style={DataGrid}>{props.curentManifest.Status}</Text>
                                </Flex>
                            </Flex>
                        </Box>

                        <Box flex={2.5} borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' alignItems='center' justifyContent='center' >
                            <MapScreen 
                            TourData={ActiveEditTour !== undefined && ActiveEditTour.length !== 0 && UnsetEditTour !== undefined? [...ActiveEditTour, ...UnsetEditTour] : []} 
                            computeRoute={{ polyline: props.RouteObj.computeRoutePoly, data: props.RouteObj.computeRouteData }}
                            editMap={false} 
                            AllowComputeRoute 
                            ActiveUser={props.ActiveUser} 
                            MapCall={MapCall}
                            />
                        </Box>
                    </Flex>
                    <Flex flex={1} flexDirection='column'>

                        <Routes>
                            <Route path="/sort" element={
                                <MultiSortierListe TourData={props.TourData} TourUnset={props.TourUnset} sortierCall={sortierCall} ShowCustomer={ShowCustomer}/>
                            }/>
                            <Route index element={
                            <>
                            <Box flex={3} ml={1} borderWidth={1} borderRadius={3} borderColor='#000' bgColor='#fff' overflow='hidden' >
                                <Flex flexDirection='column'>
                                    <Flex flex={1} flexWrap='wrap' justifyContent='space-between' alignItems='space-evenly' mt={2}>
                                        
                                        <HStack spacing={0.5} mx={2} mb={1}>
                                            <Tooltip label="Verändere die Reihenfolge innerhalb der Tour" placement='top' hasArrow bg='#666'>
                                                <Button colorScheme='twitter' borderRadius={'5px 0px 0px 5px'} isDisabled={!props.UserRights.tour_manage}
                                                    onClick={() => navigate( props.route + `/sort?${searchParams.toString()}`) } 
                                                >Sortieren</Button>
                                            </Tooltip>
                                            <Tooltip label="Verschiebe Abladestellen in eine andere Tour" placement='top' hasArrow bg='#666'>
                                                <Button colorScheme='twitter' borderRadius={0} isDisabled={!props.UserRights.tour_manage}
                                                    onClick={() => navigate( props.route + `/transfer?${searchParams.toString()}`) } 
                                                >Transferieren</Button>
                                            </Tooltip>
                                            <Tooltip label="Dreht die Reihenfolge der Abladestellen um" placement='top' hasArrow bg='#666'>
                                                <Button colorScheme='twitter' borderRadius={'0px 5px 5px 0px'} onClick={() => props._sortierCall('InvertRoute')} isDisabled={!props.UserRights.tour_manage}> 
                                                Invertieren</Button>
                                            </Tooltip>
                                        </HStack>

                                        <HStack spacing={0.5} mx={2} mb={1}>
                                            <Tooltip label="Zeichnet eine neue Strecke" placement='top' hasArrow bg='#666'>   
                                                <Button colorScheme='orange' borderRadius={'5px 0px 0px 5px'} onClick={() => props._sortierCall('getRoute')} isDisabled={!props.UserRights.tour_manage} >Strecke Generieren</Button>
                                            </Tooltip>
                                            <Tooltip label="Ordnet die Tour und zeichnet eine neue Strecke" placement='top-start' hasArrow bg='#666'>
                                                <Button colorScheme='orange' borderRadius={'0px 5px 5px 0px'} onClick={() => props._sortierCall('getOptiRoute')} isDisabled={!props.UserRights.tour_manage || props.TourData.length > 25} >Optimierte Strecke Generieren</Button>
                                            </Tooltip>
                                        </HStack>

                                    </Flex>
                                    <Flex flex={1} flexWrap='wrap' justifyContent='space-between' alignItems='space-evenly'>
                                        <Button  mx={2} mb={1} colorScheme='twitter' variant='outline' 
                                            onClick={() => CustomerVisibility()}
                                        >{ShowCustomer ? ('Verstecke Kunden'):('Zeige Kunden')}
                                        </Button>

                                        <HStack spacing={0.5} mx={2} mb={1}>
                                            <Tooltip label="Das Manifest als PDF ausgeben" placement='top' hasArrow bg='#666'>
                                                <Button colorScheme='green' borderRadius={'5px 0px 0px 5px'} onClick={() => makeBeladePDF(props.TourData[0].Manifest)} >Papier-Manifest Erstellen</Button>
                                            </Tooltip>
                                            <Tooltip label="Die Beladeliste als PDF ausgeben" placement='top-start' hasArrow bg='#666'>
                                                <Button colorScheme='green' borderRadius={'0px 5px 5px 0px'} onClick={() => makePaketPDF(props.TourData[0].Manifest)} >Beladeliste Erstellen</Button>
                                            </Tooltip>
                                        </HStack>

                                    </Flex>
                                    <Flex flex={1} maxH='60vh' pb={1} overflow='auto' flexDirection='column'>
                                        {props.TourData.map((item, index) => {
                                            return (
                                                <Flex key={item.AbladeID} bg={() => colorMaker(item.Status)} marginTop={1} mx={1} borderWidth={1} borderRadius={3} borderColor='#000' justifyContent='space-between' backgroundColor={item.Updated === 'D' || item.Updated === 'Deleted' ? ('#f98686') : (null)} minH='52px' flex='1'>
                                                    <Flex flex='1'>
                                                        <Center bg='#9ace93' minW={50} minH={50} mr={5} text-align='center'>
                                                           <Text fontSize={32} fontWeight='bold'>{index + 1}</Text>
                                                        </Center>
                                                        <Box flex='4' textAlign='left' flexWrap='wrap'>
                                                        <Flex>
                                                            <Text fontWeight="bold">
                                                                {" "}
                                                                {ShowCustomer ? "> " + item.Lieferstellen[0].Name1 + " | " : null}
                                                                {item.Straße} | {item.PLZ} {item.Ort}{" "}
                                                            </Text>	
                                                            {item.AbladeHinweis !== '' ? (
                                                                <Tooltip shouldWrapChildren label={item.AbladeHinweis} placement='top' hasArrow bg='#666' fontSize={16} offset={[0,0]}>
                                                                    <Icon marginLeft={2} marginTop='2px' as={FiAlertCircle} color="#0083c0" boxSize={'18px'} />
                                                                </Tooltip>
                                                            ):(null)}
                                                        </Flex>

                                                            <Text>{item.Kennzeichen} || {item.Bezeichnung} || {item.Liefer_Anzahl} Lieferstelle/n || {item.Pakete_Anzahl} Paket/e || {item.Papier_Anzahl} x Papier </Text>
                                                        </Box>
                                                    </Flex>
                                                    <Center w={50} h={50}>
                                                        <IconButton colorScheme='green' icon={
                                                            <Icon as={FiSettings} color='#fff' boxSize={18} />
                                                        } 
                                                        //onClick={() => itemCall(index, index * 55 + 50, 'set')} 
                                                        onClick={() => setEditAblade(index)}
                                                        />
                                                    </Center>
                                                </Flex>
                                            )
                                        })}
                                    </Flex>
                                </Flex>
                            </Box>
                            <Box flex={1} ml={1} mt={1} borderWidth={1} borderRadius={3} borderColor='#000' bgColor='#fff' overflow={'auto'} >

                                <Text m={1} fontSize={25} fontWeight={'bold'}>Deaktivierte Abladestellen:</Text>

                                {props.TourUnset.map((item, index) => {
                                    return (
                                        <Flex key={item.AbladeID} bg={() => colorMaker(item.Status)} marginTop={1} mx={1} borderWidth={1} borderRadius={3} borderColor='#000' flex='row' justifyContent='space-between' backgroundColor={item.Updated === 'D' || item.Updated === 'Deleted' ? ('#f98686') : (null)} minH='52px'>
                                            <Flex flex='row' >
                                                <Center bg='#9ace93' minW={50} h={50} mr={5} text-align='center'>
                                                    <Text fontSize={32} fontWeight='bold'>{index + 1 + ActiveEditTour.length}</Text>
                                                </Center>
                                                <Box flex='4' textAlign='left' >
                                                    <Text fontWeight='bold' >
                                                    {ShowCustomer ? ('> ' + item.Lieferstellen[0].Name1 +' | '):(null)}{item.Straße} | {item.PLZ} {item.Ort} </Text>
                                                    <Text>{item.Kennzeichen} || {item.Bezeichnung} || {item.Liefer_Anzahl} Lieferstelle/n || {item.Pakete_Anzahl} Paket/e || {item.Papier_Anzahl}x Papier </Text>
                                                </Box>
                                            </Flex>
                                        </Flex>
                                )})}
                            </Box>
                            </>
                            }/>
                            <Route path="*" element={<Navigate to={props.route + `?${searchParams.toString()}`} />}/>
                        </Routes>
                    </Flex>
                </Flex>
                <BaseModal isOpen={Modal2} onClose={() => setModal2(false)} 
                    style={{width: '350px'}}
                    >
                    {Drivers.map((user, index) => {
                        return (

                            <Button key={user.User_ID} width='98%' alignItems='center' bgColor='#fff' m={1}
                                borderRadius={5} onClick={() => selectDriver(user.User_ID)}>
                                <Text fontSize={20} >{user.User_Name}</Text>
                                <Spacer />
                                <Text fontSize={17} mx={2}>{user.Role_Name}</Text>
                            </Button>
                        )
                    })}
                </BaseModal>
            </Flex >
        )
    }


    return(
        <Routes>
            <Route path="/*" element={ManifestEditRender()}/>
            <Route path="transfer/*" element={ //Manifest Sortier Component
                <MoveAblade TourData={props.TourData} MoveCall={MoveCall} UserRights={props.UserRights} ActiveUser={props.ActiveUser} server={props.server} curentManifest={props.curentManifest} ShowCustomer={ShowCustomer}/> 
            }/>
            
            <Route path="ablade/*" element={ ActiveEditTour.length !== 0 ? (  //editAblade Component
                <EditAbladeSt AbladeSt={ActiveEditTour[A_EditIndex]} EditAbladeCall={EditAbladeCall} UserRights={props.UserRights} ActiveUser={props.ActiveUser} server={props.server} route={props.route + "/ablade"} />
            ):(null) //only render EditAbladeStelle after TourData has been loaded 
            }/> 
            
        </Routes>
    );

        /*
        )*/;
}

const DataGrid = {
    fontSize: 20, 
    marginTop: -2, 
    height: '25px'
}

