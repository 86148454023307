import React, { useEffect, useState } from "react";
import { Box, Text, Wrap, Accordion, Flex, Center, Button, Icon, IconButton} from '@chakra-ui/react';
import { DndContext, DragOverlay, closestCorners, useDroppable, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates, useSortable, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

function Item(props) {
  const { id, index } = props;
  
  const item = props.Data;
  /*
  return(
    <div style={{height: 30, margin: 2, border: '1px solid black', backgroundColor: '#fff'}}>
      {index} - {id}
    </div>
  )
    */
  
  return (
    <Flex
    bg='#fff' m={1} borderWidth={1} borderRadius={3} borderColor='#000' flex='row' justifyContent='space-between' 
    >
        <Flex flex={1} flexDirection='row'>
            <Center bg='orange' w={50} mr={5} text-align='center'>
                <Text fontSize={32} fontWeight='bold'>
                  {
                    props.index
                  }
                </Text>
            </Center>
            <Box flex='4' textAlign='left'>
                <Text fontWeight='bold' >{ props.ShowCustomer ? ('> ' + item.Lieferstellen[0].Name1 +' | '):(null)}{item.Straße} | {item.PLZ} {item.Ort} </Text>
                <Text>{item.Kennzeichen} || {item.Bezeichnung} || {item.Liefer_Anzahl} Lieferstelle/n || {item.Pakete_Anzahl} Paket/e || {item.Papier_Anzahl} x Papier </Text>
            </Box>
            <Box maxWidth={50} display='flex' flex={1} alignItems='center'  zIndex={10}>
              <IconButton bg='#1DA1F2' color='#fff' maxWidth='30px' height='30px' 

                                    onPointerDown={(e) => {
                                      e.preventDefault();
                                    }}
                                    onClick={(e) => {
                                      props.quickSortButton(props.unset,index-1)
                                    }} //isDisabled={true}
                                    onMouseDown={(e) => e.stopPropagation()}
                                    onMouseUp={(e) => e.stopPropagation()}
                                    icon={<Icon as={ props.unset ? IoMdEye : IoMdEyeOff } color='#fff' boxSize={25}/>}/>
            </Box>
        </Flex>
    </Flex>
  )
  
}

function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Item id={props.id} index={props.index} Data={props.Data} ShowCustomer={props.ShowCustomer} unset={props.unset} quickSortButton={props.quickSortButton}/>
    </div>);

  //return (  <Item id={props.id} index={props.index} Data={props.Data} ShowCustomer={props.ShowCustomer} unset={props.unset} quickSortButton={props.quickSortButton}/> );
}

// -w ------------------------------------------

function Container(props) {
  const { id, items, style, unset } = props;

  const { setNodeRef } = useDroppable({
    id
  });

  return (
    <SortableContext
      id={id}
      items={items}
      strategy={verticalListSortingStrategy}
    >
      <div ref={setNodeRef} style={style}>
        {items.map((id, index) => (
          <SortableItem key={id} id={id} 
          index={unset ? props.Length + index + 1 : index + 1 } 
          unset={unset} quickSortButton={props.quickSortButton}
          Data={props.DataObject[id]}  ShowCustomer={props.ShowCustomer}/>
        ))}
      </div>
    </SortableContext>
  );
}

// -w -----------------------------------------

const defaultAnnouncements = {
  onDragStart(id) {
    console.log(`Picked up draggable item ${id}.`);
  },
  onDragOver(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was moved over droppable area ${overId}.`
      );
      return;
    }

    console.log(`Draggable item ${id} is no longer over a droppable area.`);
  },
  onDragEnd(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was dropped over droppable area ${overId}`
      );
      return;
    }

    console.log(`Draggable item ${id} was dropped.`);
  },
  onDragCancel(id) {
    console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
  }
};

export default function MultiSortierListe(props) {

  //props.TourData
  //props.TourUnset

  const [items, setItems] = useState({
    container1: [],
    container2: [],
  });
  const [ActiveCurrent, setActiveCurrent] = useState(null);

  const [DataObject, setDataObject] = useState({});
  //const [ReloadTimer, setReloadTimer] = useState(0);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  useEffect(() => {

    const resultObject = [...props.TourData, ...props.TourUnset ].reduce((accumulator, currentObject) => {
      accumulator[currentObject.AbladeID] = currentObject;
      return accumulator;
    }, {});

    setDataObject(resultObject);

    var activeArray = props.TourData.map(item => (item.AbladeID));
    var unsetArray = props.TourUnset.map(item => (item.AbladeID));
    
    setItems({
      container1: activeArray,
      container2: unsetArray
    })
    console.log('useState');

  }, [props.TourData, props.TourUnset]);
   
  function saveOrder() {

    //console.log(items)
    props.sortierCall('save');
    //props.sortierCall('save', items.container1, items.container2);

  }

  return (
      <Flex style={{flex: 1, flexDirection: 'column', overflow: 'auto'}}>
        <Box ml={1} mb={1} borderWidth={1} borderRadius={3} borderColor='#000' bgColor='#fff' >
          <Button onClick={() => saveOrder()} width='170px' margin='10px' colorScheme='twitter'>Sortierung Speichern</Button>
        </Box>
        <Box flex={1} ml={1} borderWidth={1} borderRadius={3} borderColor='#000' bgColor='#fff' overflowY='auto'>

          <DndContext
            announcements={defaultAnnouncements}
            sensors={sensors}
            collisionDetection={closestCorners}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}
          >
           
            <Text m={1} fontSize={22} fontWeight={'bold'}>Aktive Abladestellen:</Text>
            <Container id="container1" items={items.container1} DataObject={DataObject} ShowCustomer={props.ShowCustomer}
            quickSortButton={quickSortButton}
            style={{ flex: 1, padding: 7 }} unset={false}/>
            <Text m={1} fontSize={22} fontWeight={'bold'}>Deaktivierte Abladestellen:</Text>
            <Container id="container2" items={items.container2} DataObject={DataObject} Length={items.container1.length} 
            ShowCustomer={props.ShowCustomer} quickSortButton={quickSortButton}
            style={{flex: 1, padding: 7}} unset={true}/>

            {/*
            <DragOverlay>{activeId ? <Item id={activeId} /> : null}</DragOverlay>
            */}
          </DndContext>
        </Box>
      </Flex>
  );
  
  function quickSortButton(container,index){

    var AContainer = items.container1;
    var UContainer = items.container2; 

    if (container) {

      index = index - AContainer.length;

      var moveItem = UContainer.splice(index, 1);
      AContainer.push(moveItem);
      var start = { container: "container2", index: index };
      var end = { container: "container1", index: AContainer.length -1 };

    } else {

      var moveItem = AContainer.splice(index, 1);
      UContainer.push(moveItem);
      var start = { container: "container1", index: index };
      var end = { container: "container2", index: UContainer.length -1 };

    }
    //console.log(start, end)
    props.sortierCall('sort', start, end);

    console.log(AContainer, UContainer)

    setItems({ container1: AContainer, container2: UContainer });

  } 
  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => items[key].includes(id));
  }
  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    const activeContainer = findContainer(id);
    const activeItems = items[activeContainer];
    const activeIndex = activeItems.indexOf(id);

    setActiveCurrent({container: activeContainer , index: activeIndex});

  }
  function handleDragOver(event) {

    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];


      // Find the indexes for the items
      const activeIndex = activeItems.indexOf(id);
      const overIndex = overItems.indexOf(overId);

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem = (over && overIndex === overItems.length - 1) 
        //  && draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }
      
      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer].filter((item) => item !== active.id)
        ],
        [overContainer]: [
          ...prev[overContainer].slice(0, newIndex),
          items[activeContainer][activeIndex],
          ...prev[overContainer].slice(newIndex, prev[overContainer].length)
        ]
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    if( over === null || over === undefined ) return;
    const { id: overId } = over;

    console.log(active, over)

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }
    //console.log(active.data.current.sortable)

    const activeIndex = items[activeContainer].indexOf(active.id);
    const overIndex = items[overContainer].indexOf(overId);

    var start = { 
                  container: ActiveCurrent != null ? ActiveCurrent.container : null,
                  index: ActiveCurrent != null ? ActiveCurrent.index : null
                };
    var end = { container: overContainer, index: overIndex };

    //console.log(start, end);
    props.sortierCall('sort', start, end);

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
      }));
    }

    setActiveCurrent(null);
  }
}